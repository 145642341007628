const i18nYamls = import.meta.glob('../../../config/locales/*.yml')

export const appLocale = document.getElementById('appLocale').dataset.locale

export const getI18nParams = async () => {
  const i18nParams = {
    locale: appLocale,
    fallbackLocale: 'en',
    messages: {}
  }
  i18nParams.messages  = (await i18nYamls[`../../../config/locales/en.yml`]()).default
  if (appLocale !== 'en') {
    i18nParams.messages = {
      ...i18nParams.messages,
      ...(await i18nYamls[`../../../config/locales/${appLocale}.yml`]()).default
    }
  }
  return i18nParams
}
